'use strict';

import "prismjs"

// import { languages } from "prismjs"

// const loadLanguages = require('prismjs/components/index');

// import "prismjs/components/prism-markup-templating.js"
// import "prismjs/components/prism-systemd.js"
// import "prismjs/components/prism-markdown.js"
// import "prismjs/components/prism-autohotkey.js"
// import "prismjs/components/prism-bash.js"
// import "prismjs/components/prism-git.js"
// import "prismjs/components/prism-css.js"
// import "prismjs/components/prism-sass.js"
// import "prismjs/components/prism-dot.js"

// loadLanguages(['php', 'ruby', 'markup-templating', 'systemd', 'markdown', 'bash', 'autohotkey', 'git', 'dot']);

require('./src/assets/scss/init.scss');
require('./static/css/prismjs/theme.min.css');
// require('./node_modules/prismjs/themes/prism-tomorrow.css');
// require('./node_modules/prismjs/plugins/line-numbers/prism-line-numbers.css');

// Prism.highlightAll();
